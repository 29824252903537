import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{attrs:{"two-line":!_vm.long,"three-line":_vm.long,"tabindex":_vm.clickable === true}},[_c(VListItemIcon,[_c(VIcon,{staticClass:"pt-1 pb-0"},[_vm._v(_vm._s(_vm.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.information))]),_c(VListItemContent,{staticClass:"pa-0 body-2"},[_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }