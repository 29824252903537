<template>
  <v-container class="pa-0">
    <v-toolbar>
      <v-btn @click="$router.go(-1)" color="primary">
        <v-icon>mdi-arrow-left</v-icon>
        Retour à la liste
      </v-btn>
    </v-toolbar>
    <v-row class="mt-4" align="center" justify="center" no-gutters>
      <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
      <v-col cols="8" v-else>
        <v-card>
          <v-row no-gutters justify="space-between">
            <v-card-title class="primary--text">
              {{ entreprise.name }}
            </v-card-title>
            <div>
              <entreprise-dialog @reload="loadEntreprise" operation="update" :entreprise-object="entreprise">
                <template v-slot:activator="{on, attrs}">
                  <v-btn v-on="on" v-bind="attrs" text color="primary">
                    <v-icon>mdi-pencil</v-icon>
                    Modifier
                  </v-btn>
                </template>
              </entreprise-dialog>
              <v-dialog width="600">
                <template v-slot:activator="{on, attrs}">
                  <v-btn v-bind="attrs" v-on="on" color="red" text>
                    <v-icon>mdi-trash-can</v-icon>
                    Supprimer
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar color="red">
                    <v-toolbar-title v-if="operation === 'create'" class="white--text">Ajouter une entreprise
                    </v-toolbar-title>
                    <v-toolbar-title v-else class="white--text">Mettre à jour l'entreprise</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text class="mt-4">
                    Confirmer la suppression de l'entreprise ?
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="deleteEntreprise" class="white--text" color="red">Supprimer</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-row>
          <div>
            <v-chip class="mx-2" color="primary" v-for="metier in entreprise.metiers" :key="metier.id">
              {{ metier.name }}
            </v-chip>
          </div>
          <v-row>
            <v-col cols="4">
              <detail-card icon="mdi-map-marker" name="Adresse" :information="entreprise.adresse"></detail-card>
            </v-col>
            <v-col cols="4">
              <detail-card icon="mdi-office-building" name="SIRET" :information="entreprise.siret"></detail-card>
            </v-col>
            <v-col cols="4">
              <detail-card icon="mdi-email" name="E-mail" :information="entreprise.email"></detail-card>
            </v-col>
            <v-col cols="4">
              <detail-card icon="mdi-phone-outline" name="Téléphone" :information="entreprise.tel"></detail-card>
            </v-col>
            <v-col cols="4">
              <detail-card icon="mdi-currency-eur" name="IBAN" :information="entreprise.iban"></detail-card>
            </v-col>
            <v-col cols="4">
              <detail-card icon="mdi-currency-eur" name="BIC" :information="entreprise.bic"></detail-card>
            </v-col>
          </v-row>
          <div class="pa-2">
            <h3>Notes</h3>
            <p>{{ entreprise.note || "Non défini" }}</p>
          </div>
          <v-row class="pa-4" justify="space-between" no-gutters>
            <h2 class="primary--text">Documents</h2>
            <EntrepriseAttachmentDialog @reload="loadEntreprise" :entreprise="entreprise"/>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4" v-for="attachment in entreprise.attachments" :key="attachment.id">
              <div class="d-flex">
                <detail-card class="flex-grow-1" icon="mdi-file-document"
                             :name="attachment.type"
                             clickable
                             @click.native="openFile(attachment.file)"
                             :information="getFilename(attachment.file)">
                  <div v-if="attachment.date_validite !== null">
                    <span class="red--text" v-if="isAfter(attachment.date_validite) === 'passed'">
                      Document expiré
                    </span>
                    <span class="orange--text" v-if="isAfter(attachment.date_validite) === 'soon'">
                      Expire bientôt (le {{ getFormattedDate(attachment.date_validite) }})
                    </span>
                    <span class="green--text" v-if="isAfter(attachment.date_validite) === 'ok'">
                      Valide jusqu'au {{ getFormattedDate(attachment.date_validite) }}
                    </span>

                  </div>
                </detail-card>
                <v-dialog max-width="800">
                  <template v-slot:activator="{on, attrs}">
                    <v-btn class="align-self-center" v-bind="attrs" v-on="on" icon color="red">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar color="red">
                      <v-toolbar-title class="white--text">Supprimer le document</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-2">
                      Confirmer la suppression du document ?
                    </v-card-text>
                    <v-card-actions>
                      <v-btn :disabled="deleting" color="red" class="white--text" @click="deleteAttachment(attachment)">
                        Supprimer
                      </v-btn>
                      <v-progress-circular color="red" v-if="deleting" indeterminate></v-progress-circular>
                    </v-card-actions>

                  </v-card>
                </v-dialog>
              </div>
            </v-col>
          </v-row>
          <v-simple-table dense>
        <template>
          <thead>
          <tr>
            <th>Saisie</th>
            <th>Date de règlement</th>
            <th>Dossier</th>
            <th>Montant</th>
            <th>Date de la facture</th>
            <th>Référence de la facture</th>
            <th>Statut</th>
            <th>Justificatif</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="reglement in reglements" :key="reglement.id">
            <td>{{reglement.saisie_source}}</td>
            <td>{{ getFormattedDate(reglement.date_reglement) }}</td>
            <td>
              <router-link class="text-decoration-none" :to="'/finances/' + reglement.dossier_id">
              {{ reglement.beneficiaire_name }}
            </router-link>
            </td>
            <td>{{ reglement.montant }} €</td>
            <td>{{getFormattedDate(reglement.date_facture)}}</td>
            <td>{{reglement.reference_facture}}</td>
            <td>
              <reglement-status-chip  :reglement="reglement"></reglement-status-chip>
            </td>
            <td>
              <v-btn target="_blank" :href="getUrl(reglement.file)" icon color="primary" v-if="reglement.file">
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {EntrepriseAttachmentRepository, EntrepriseRepository} from "@/repository/entreprises";
import DetailCard from "@/components/base/DetailCard";
import EntrepriseAttachmentDialog from "@/views/EntrepriseAttachmentDialog";
import dayjs from "dayjs";
import {DateUtilMixin} from "@/mixins/DateUtilMixin";
import EntrepriseDialog from "@/views/EntrepriseDialog";
import ReglementStatusChip from "@/components/finances/ReglementStatusChip.vue";
import {ReglementRepository} from "@/repository/reglement";
import {TYPES_ENCAISSEMENT} from "@/constants/Financement";
import * as config from "@/config.json";

export default {
  name: "EntrepriseDetails",
  components: { ReglementStatusChip, EntrepriseDialog, EntrepriseAttachmentDialog, DetailCard},
  mixins: [DateUtilMixin],
  props: {
    operation: {
      type: String,
      default: "create",
    }
  },
  async mounted() {
    await this.loadEntreprise();
  },
  data() {
    return {
      repository: new EntrepriseRepository(),
      loading: true,
      entreprise: null,
      deleting: false,
      reglements: [],
    }
  },
  methods: {
    getFilename(path) {
      let elements = path.split("/")
      return elements[elements.length - 1]
    },
    async loadEntreprise() {
      this.loading = true;
      let dossierId = this.$route.params?.id;
      this.entreprise = await this.repository.getById({id: dossierId});
      let reglementRepository = new ReglementRepository();
      this.reglements = await reglementRepository.fetchReglementsByEntrepriseId(this.entreprise.id);
      this.loading = false;
    },
    openFile(url) {
      console.log(url);
      window.open(url);
    },
    getUrl(path) {
      return config.BASE_URL + path;
    },
    isAfter(date) {
      if (dayjs(date).isAfter(dayjs())) {
        return dayjs(date).diff(dayjs(), "month") === 0 ? "soon" : "ok";
      } else {
        return "passed";
      }
    },
    async deleteAttachment(attachment) {
      this.deleting = true;
      let attachmentRepository = new EntrepriseAttachmentRepository();
      await attachmentRepository.delete(attachment);
      this.loadEntreprise();
      this.deleting = false;
    },
    async deleteEntreprise() {
      let repo = new EntrepriseRepository();
      await repo.delete(this.entreprise);
      await this.$router.push("/entreprises");
    }
  },
  computed: {
    factures() {
      return this.reglements.filter(reglement => TYPES_ENCAISSEMENT.includes(reglement.type));
    }
  }
}
</script>

